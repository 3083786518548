import {Wrapper, Status} from "@googlemaps/react-wrapper";
import {MapComponent} from "../../components/maps/Map";
import InfoWindow from "../../components/maps/InfoWindow";
import Marker from "../../components/maps/Marker";

export const renderMap = function (state, onMapClicked, onMarkerClick) {
    return (
        <div>
            <Wrapper apiKey="AIzaSyCGGO3uwp6FRmVMdjcrXdTLVXyAOdn_v4U" render={render}>
                <MapComponent
                    zoom={8}
                    center={{lat: 30.379055, lng: -96.776215}}
                    style={{width: "1290px", height: "1000px"}}
                    onClick={onMapClicked}
                >
                    {displayMarkers(state.lands, onMarkerClick)}
                    <InfoWindow
                        marker={state.activeMarker}
                        visible={state.showingInfoWindow}
                    >
                        {getInfoWindowText(state.selectedPlace.land)}
                    </InfoWindow>
                </MapComponent>
            </Wrapper>
        </div>
    );
};

const render = (status) => {
    if (status === Status.FAILURE) return "Error";
    return "Creating Map...";
};


const getInfoWindowText = function (land) {
    if (land?.lastOfferExpirationDate) {
        return (
            <div>
                <table>
                    <tr>
                        <th>Street</th>
                        <th>{land.street}</th>
                    </tr>
                    <tr>
                        <th>Acres</th>
                        <th>{land.acres}</th>
                    </tr>
                    <tr>
                        <th>Calc'd Market Price</th>
                        <th>{land.lastCalculatedMarketPrice}</th>
                    </tr>
                    <tr>
                        <th>Offer Price</th>
                        <th>{land.lastOfferPrice}</th>
                    </tr>
                    <tr>
                        <th>Discount</th>
                        <th>{land.lastDiscount}</th>
                    </tr>
                    <tr>
                        <th>Expiration Date</th>
                        <th>{land.lastOfferExpirationDate}</th>
                    </tr>
                    <tr>
                        <th>Offer Days</th>
                        <th>{land.offerDurationDays}</th>
                    </tr>
                    <tr>
                        <th>Days Since Expiration</th>
                        <th>{land.daysSinceLastExpiration}</th>
                    </tr>
                </table>
            </div>
        );
    } else {
        return (
            <div>
                <table>
                    <tr>
                        <th>Street</th>
                        <th>{land.street}</th>
                    </tr>
                    <tr>
                        <th>Acres</th>
                        <th>{land.acres}</th>
                    </tr>
                </table>
            </div>
        );
    }
};


const displayMarkers = function (lands, onMarkerClick) {
    return lands.map((land, index) => {
        return (
            <Marker
                key={land.productId}
                onClick={onMarkerClick}
                position={{
                    lat: land.latitude,
                    lng: land.longitude,
                }}
                icon={{
                    url: getIcon(land.daysSinceLastExpiration),
                }}
                content={getInfoWindowText(land)}
                land={land}
            />
        );
    });
};

const getIcon = function (daysSinceLastExpiration) {
    if (daysSinceLastExpiration === null) {
        return "https://storage.googleapis.com/artifacts.landpal.land/icons/maps/material/geotags/blue.png";
    } else if (daysSinceLastExpiration < 1) {
        return "https://storage.googleapis.com/artifacts.landpal.land/icons/maps/material/geotags/red.png";
    } else if (daysSinceLastExpiration < 31) {
        return "https://storage.googleapis.com/artifacts.landpal.land/icons/maps/material/geotags/orange.png";
    } else if (daysSinceLastExpiration < 61) {
        return "https://storage.googleapis.com/artifacts.landpal.land/icons/maps/material/geotags/yellow.png";
    } else {
        return "https://storage.googleapis.com/artifacts.landpal.land/icons/maps/material/geotags/green.png";
    }
};


//icons site
//https://icons8.com/icon/set/maps/material