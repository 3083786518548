import { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";

export default function InfoWindow({ marker, visible, children, map }) {
  const [infoWindow, setInfoWindow] = useState();
  const content = ReactDOMServer.renderToString(children);

  useEffect(() => {
    if (!infoWindow) {
      setInfoWindow(
        new window.google.maps.InfoWindow({
          content,
        })
      );
    }
    // remove infoWindow from map on unmount
    return () => {
      infoWindow?.close();
    };
  }, [content, infoWindow]);

  useEffect(() => {
    if (infoWindow && visible) {
      infoWindow.setContent(content)
      infoWindow.open(map, marker);
    } else if (infoWindow && !visible) {
      infoWindow.close();
    }
  }, [content, infoWindow, map, marker, visible]);

  return null;
}
