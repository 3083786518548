const getRedirectUri = () => {
    if (process.env.NODE_ENV === 'production')
        return "https://campaigns-app.landpal.com/";

    if (process.env.NODE_ENV === 'test')
        return "https://campaigns-app.test.landpal.com/"

    return "https://localhost:44489";
};

export const msalConfig = {
    auth: {
        clientId: "980d9f07-065a-44f9-824f-84f1b72e189f",
        authority: "https://login.microsoftonline.com/common", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: getRedirectUri(),
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["User.Read"]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};