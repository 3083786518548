import {Component} from "react";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {TextField, Button} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import Item from '@mui/material/Unstable_Grid2';

export class Filters extends Component {
    static displayName = Filters.name;

    constructor(props) {
        super(props);

        this.state = {
            startDate: this.props.getMethods().getState().startDate,
            endDate: this.props.getMethods().getState().endDate
        };
    }

    render() {
        return (
            <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid container spacing={2}>
                        <Grid xs="auto">
                            <Item>
                                <DatePicker
                                    label="Start Date"
                                    value={this.props.getMethods().getState().startDate}
                                    onChange={(newValue) => {
                                        this.props.getMethods().updateState({startDate: newValue});
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </Item>
                        </Grid>
                        <Grid xs="auto">
                            <Item>
                                <DatePicker
                                    label="End Date"
                                    value={this.props.getMethods().getState().endDate}
                                    onChange={(newValue) => {
                                        this.props.getMethods().updateState({endDate: newValue});
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </Item>
                        </Grid>
                        <Grid xs="auto">
                            <Item style={{paddingTop: 15}}>
                                <Button variant="contained"
                                        onClick={() => this.props.getMethods().populate(this.props.getMethods)}>
                                    Search
                                </Button>
                            </Item>
                        </Grid>
                    </Grid>
                </LocalizationProvider>
            </div>
        );
    }
}
