import React, { useEffect, useRef, useState } from "react";
import useDeepCompareEffectForMaps from "./Hooks";

export function MapComponent({ style, children, onClick, ...options }) {
  const ref = useRef();
  const [map, setMap] = useState();
  const { zoom, center } = options;

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map, zoom, center]);

  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  useEffect(() => {
    if (map) {
      ["click"].forEach((eventName) =>
        window.google.maps.event.clearListeners(map, eventName)
      );

      if (onClick && !window.google.maps.event.hasListeners(map, "click")) {
        map.addListener("click", (e) => {
          onClick(e);
        });
      }
    }
  }, [map, onClick]);

  return (
    <>
      <div id="map" ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
}
