import {Component} from "react";
import {Filters} from "./Filters";
import {DataGrid} from '@mui/x-data-grid';
import Grid from '@mui/material/Unstable_Grid2';
import Item from '@mui/material/Unstable_Grid2';
import {Table, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";

export class History extends Component {
    static displayName = History.name;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            startDate: null,
            endDate: null,
            campaignHistory: null
        };
    }

    getState = () => this.state;
    updateState = (object) => this.setState(object);
    getMethods = () => {
        return {
            getState: this.getState,
            updateState: this.updateState,
            populate: this.populate
        }
    }

    render() {
        let contents = this.state.loading ? (
            <p>
                <em>Loading...</em>
            </p>
        ) : (renderBody(this.getMethods));

        return (
            <div>
                <Filters getMethods={this.getMethods}/>
                {contents}
            </div>
        );
    }

    async populate(getMethods) {
        const state = getMethods().getState();
        getMethods().updateState({loading: true});
        
        const uri = 'historylogic';
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                startDate: state.startDate,
                endDate: state.endDate
            })
        };

        const response = await fetch(uri, requestOptions);
        const data = await response.json();
        getMethods().updateState({campaignHistory: data, loading: false});
    }
}


const renderBody = (getMethods) => {

    if (getMethods().getState().campaignHistory) {
        const history = getMethods().getState().campaignHistory;
        const campaigns = history.campaigns;

        const columns = [
            {field: 'name', headerName: 'Campaign Name', width: 500},
            {field: 'startDate', headerName: 'Started', width: 100},
            {field: 'expirationDate', headerName: 'Expires', width: 100},
            {field: 'offerCount', headerName: 'Offer Count', width: 100},
            {field: 'discountCoefficient', headerName: 'Discount', width: 80},
            {field: 'responseCount', headerName: 'Responded', width: 100},
            {field: 'responseRate', headerName: 'Response %', width: 100},
            {field: 'acceptedOrQuestionsCount', headerName: 'AorQ', width: 80},
            {field: 'acceptedOrQuestionsRate', headerName: 'AorQ %', width: 100},
            {field: 'acceptedCount', headerName: 'Accepted', width: 80},
            {field: 'acceptedRate', headerName: 'Accepted %', width: 100},
            {field: 'hasQuestionsCount', headerName: 'Questions', width: 80},
            {field: 'hasQuestionsRate', headerName: 'Questions %', width: 100},
            {field: 'declinedCount', headerName: 'Declined', width: 80},
            {field: 'declinedRate', headerName: 'Declined %', width: 100}
        ];

        if (campaigns)
            return (
                <div>
                    <Grid container spacing={2}>
                        <Grid xs>
                            <Item>
                                <TableContainer component={Paper}>
                                    <Table sx={{minWidth: 650}} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Campaign&nbsp;Count</TableCell>
                                                <TableCell align="right">Offer&nbsp;Count</TableCell>
                                                <TableCell align="right">Active&nbsp;Count</TableCell>
                                                <TableCell align="right">Expired&nbsp;Count</TableCell>
                                                <TableCell align="right">Response&nbsp;Rate</TableCell>
                                                <TableCell align="right">AcceptedOrQuestions&nbsp;Rate</TableCell>
                                                <TableCell align="right">Declined&nbsp;Rate</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow
                                                key={"First"}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {history.campaignCount}
                                                </TableCell>
                                                <TableCell align="right">{history.offerCount}</TableCell>
                                                <TableCell align="right">{history.activeOfferCount}</TableCell>
                                                <TableCell align="right">{history.expiredOfferCount}</TableCell>
                                                <TableCell align="right">{history.responseRate}</TableCell>
                                                <TableCell align="right">{history.acceptedOrQuestionRate}</TableCell>
                                                <TableCell align="right">{history.declinedRate}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Item>
                        </Grid>
                        <Grid xs={12}>
                            <Item>
                                <DataGrid
                                    rows={campaigns}
                                    columns={columns}
                                    pageSize={15}
                                    autoHeight
                                />
                            </Item>
                        </Grid>
                    </Grid>
                </div>
            );
    }

    return (
        <div>
            <h3>Ready for search.</h3>
        </div>
    );
}