import {Home} from "./pages/Home";
import {History} from "./pages/History/History";
import OffersMap from "./pages/OffersMap/OffersMap";

const AppRoutes = [
    {
        index: true,
        element: <Home/>
    },
    {
        path: '/history',
        element: <History/>
    },
    {
        path: '/offers-map',
        element: <OffersMap/>
    }
];

export default AppRoutes;
