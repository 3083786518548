import React, {Component} from "react";
import {TextField, Button} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import Item from '@mui/material/Unstable_Grid2';

export class MapFilters extends Component {
    static displayName = MapFilters.name;

    constructor(props) {
        super(props);
        
        const parentState = props.getMethods().getState();

        this.state = {
            latLon: parentState.coords.replace(/,/g, ', '),
            distance: parentState.dist,
            minAcres: parentState.minAcres,
            maxAcres: parentState.maxAcres
        };
    }

    onCalculate = async () => {

        if (this.state.latLon) {
            await this.props.getMethods().updateState({
                coords: this.state.latLon.replace(/ /g, '')
            })
        }
        if (this.state.distance) {
            await this.props.getMethods().updateState({
                dist: this.state.distance
            })
        }
        if (this.state.minAcres) {
            await this.props.getMethods().updateState({
                minAcres: this.state.minAcres
            })
        }
        if (this.state.maxAcres) {
            await this.props.getMethods().updateState({
                maxAcres: this.state.maxAcres
            })
        }
        
        await this.props.getMethods().updateState({
            loading: true
        })

        this.props.getMethods().populate(this.props.getMethods().getState, this.props.getMethods().updateState);
    }

    render() {
        return (
            <div>
                <Grid container spacing={2}>
                    <Grid xs={3}>
                        <Item>
                            <TextField fullWidth id="outlined-basic" label="Latitude, Longitude" variant="outlined"
                                       value={this.state.latLon}
                                       onChange={(e) => this.setState({latLon: e.target.value})}/>
                        </Item>
                    </Grid>
                    <Grid xs={2}>
                        <Item>
                            <TextField id="outlined-basic" label="Distance (miles)" variant="outlined"
                                       value={this.state.distance}
                                       onChange={(e) => this.setState({distance: e.target.value})}/>
                        </Item>
                    </Grid>
                    <Grid xs={2}>
                        <Item>
                            <TextField id="outlined-basic" label="Min Acres" variant="outlined"
                                       value={this.state.minAcres}
                                       onChange={(e) => this.setState({minAcres: e.target.value})}/>
                        </Item>
                    </Grid>
                    <Grid xs={2}>
                        <Item>
                            <TextField id="outlined-basic" label="Max Acres" variant="outlined"
                                       value={this.state.maxAcres}
                                       onChange={(e) => this.setState({maxAcres: e.target.value})}/>
                        </Item>
                    </Grid>
                    <Grid xs="auto">
                        <Item style={{paddingTop: 15}}>
                            <Button variant="contained"
                                    onClick={() => this.onCalculate()}>
                                Calculate
                            </Button>
                        </Item>
                    </Grid>
                </Grid>
            </div>
        );
    }
}