import React, {Component} from "react";
import {renderMap} from './RenderMap.js';
import {useLocation} from "react-router-dom";
import {MapFilters} from "../../components/MapFilters";

class OffersMap extends Component {
    static displayName = OffersMap.name;

    constructor(props) {
        super(props);

        const searchParams = props.queryParameters;

        let dist = "40";
        if (searchParams.get('dist'))
            dist = searchParams.get('dist');

        let coords = "32.019377,-96.810219"
        if (searchParams.get('coords'))
            coords = searchParams.get('coords');

        this.state = {
            coords: coords,
            dist: dist,
            lands: [],
            mapStatus: "loading",
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {land: {}},
            polygonString: "",
            maxAcres: "",
            minAcres: ""
        };

    }

    getState = () => this.state;
    updateState = (object) => this.setState(object);
    getMethods = () => {
        return {
            getState: this.getState,
            updateState: this.updateState,
            populate: this.populate
        }
    }

    componentDidMount() {
        this.populate(this.getMethods().getState, this.getMethods().updateState);
    }

    onMarkerClick = (props, marker, e) => {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true,
        });
    };

    onMapClicked = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null,
            });
        }
    };

    getContents() {
        if (this.state.mapStatus === "loading") {
            return <p><em>Ready For Search</em></p>
        } else if (this.state.mapStatus === "searching") {
            return <p><em>Loading...</em></p>
        } else if (this.state.mapStatus === "complete") {
            return renderMap(this.state, this.onMapClicked, this.onMarkerClick);
        }
    }

    render() {

        return (
            <div>
                <MapFilters getMethods={this.getMethods}/>
                {this.getContents()}
            </div>
        );
    }

    async populate(getState, updateState) {
        const state = getState();
        updateState({mapStatus: "searching"});

        const uri = 'offerslogic/offers/polygon';
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                polygon: state.polygonString,
                minAcres: state.minAcres,
                maxAcres: state.maxAcres,
                centerCoordinates: state.coords,
                sideLength: state.dist
            })
        };

        const response = await fetch(uri, requestOptions);
        const data = await response.json();
        updateState({lands: data, mapStatus: "complete"});
    }
}

function withQueryParameters(Component) {
    return function WrappedComponent(props) {
        const location = useLocation();
        const queryParameters = new URLSearchParams(location.search);
        return <Component {...props} queryParameters={queryParameters}/>;
    }
}

export default withQueryParameters(OffersMap);

