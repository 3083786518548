import React, {Component} from 'react';
import {Route, Routes} from 'react-router-dom';
import AppRoutes from './AppRoutes';
import './custom.css';
import {Layout} from "./menu/Layout";
import {AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";
import Authorization from "./auth/Authorization";

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <AuthenticatedTemplate>
                    <Authorization>
                        <Routes>
                            {AppRoutes.map((route, index) => {
                                const {element, ...rest} = route;
                                return <Route key={index} {...rest} element={element}/>;
                            })}
                        </Routes>
                    </Authorization>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <p>You are not signed in! Please sign in.</p>
                </UnauthenticatedTemplate>
            </Layout>
        );
    }
}
