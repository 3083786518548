import { useEffect, useState } from "react";

const Marker = (options) => {
  const [marker, setMarker] = useState();

  useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
        window.google.maps.event.clearInstanceListeners(marker);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
      if (!window.google.maps.event.hasListeners(marker, "click")) {
        marker.addListener("click", function (e) {
          options.onClick({ land: options.land }, marker, e);
        });
      }
    }
  }, [marker, options]);

  return null;
};

export default Marker;
